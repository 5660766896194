.custom-background-pri {
  background-image: url('../images/classroom.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 600px; /* Full viewport height */
  width: 100vw;  /* Full viewport width */
  max-width: none; /* Remove any maximum width constraints */
  margin: 0; /* Ensure no margins are causing spacing issues */
  padding: 0; /* Ensure no padding is affecting layout */
}

.custom-color {
  color: #dcf0dc;
}

.first-left-lg {
  height: 100%;
  max-width: 100%;
  background: 
    linear-gradient(to right, 
      rgba(253, 253, 253, 1) 0%, 
      rgba(253, 253, 253, 0.5) 10%, 
      rgba(253, 253, 253, 0.4) 20%, 
      rgba(253, 253, 253, 0.3) 30%, 
      rgba(253, 253, 253, 0.2) 40%, 
      rgba(253, 253, 253, 0.1) 50%, 
      rgba(253, 253, 253, 0.05) 60%, 
      transparent 70%),
    linear-gradient(to bottom, 
      rgba(253, 253, 253, 1) 0%, 
      rgba(253, 253, 253, 0.5) 10%, 
      rgba(253, 253, 253, 0.4) 20%, 
      rgba(253, 253, 253, 0.3) 30%, 
      rgba(253, 253, 253, 0.2) 40%, 
      rgba(253, 253, 253, 0.1) 50%, 
      rgba(253, 253, 253, 0.05) 60%, 
      transparent 70%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top left, top left;
}
.first-left-sm {
  height: 100%;
  max-width: 100%;
  background: 
    linear-gradient(to top left, 
      rgba(253, 253, 253, 1) 0%, 
      rgba(253, 253, 253, 0.8) 20%, 
      rgba(253, 253, 253, 0.5) 50%, 
      rgba(253, 253, 253, 0.3) 70%, 
      rgba(253, 253, 253, 0.1) 90%, 
      rgba(253, 253, 253, 0) 100%),
    linear-gradient(to bottom right, 
      rgba(253, 253, 253, 1) 0%, 
      rgba(253, 253, 253, 0.8) 20%, 
      rgba(253, 253, 253, 0.5) 50%, 
      rgba(253, 253, 253, 0.3) 70%, 
      rgba(253, 253, 253, 0.1) 90%, 
      rgba(253, 253, 253, 0) 100%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top left, bottom right;
}
.first-left-accounts {

  background: 
    linear-gradient(to top left, 
      rgba(253, 253, 253, 1) 0%, 
      rgba(253, 253, 253, 0.8) 20%, 
      rgba(253, 253, 253, 0.5) 50%, 
      rgba(253, 253, 253, 0.3) 70%, 
      rgba(253, 253, 253, 0.1) 90%, 
      rgba(253, 253, 253, 0) 100%),
    linear-gradient(to bottom right, 
      rgba(253, 253, 253, 1) 0%, 
      rgba(253, 253, 253, 0.8) 20%, 
      rgba(253, 253, 253, 0.5) 50%, 
      rgba(253, 253, 253, 0.3) 70%, 
      rgba(253, 253, 253, 0.1) 90%, 
      rgba(253, 253, 253, 0) 100%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top left, bottom right;
}



.font-weight-light {
  font-family: 'Lato', sans-serif;
  font-weight: 100; /* Light weight */
  font-size: 2em; /* Adjust size as needed */
  letter-spacing: 0.05em; /* Adjust letter spacing */
  line-height: 1.2; /* Adjust line height for spacing between lines */
}

.font-weight-bold {
  font-family: 'Lato', sans-serif;
  font-weight: 700; /* Light weight */
  font-size: 0.9em; /* Adjust size as needed */

  line-height: 0.5; /* Adjust line height for spacing between lines */
}


.large-font {
  font-size: 3em; /* Adjust size as needed */
}
/* Light Font Style for Small Screens */
.font-weight-light-sm {
  font-family: 'Lato', sans-serif;
  font-weight: 100; /* Light weight */
  font-size: 2em; /* Adjust size as needed */
  letter-spacing: 0.05em; /* Adjust letter spacing */
  line-height: 1.2; /* Adjust line height for spacing between lines */
}

/* Bold Font Style for Small Screens */
.font-weight-bold-sm {
  font-family: 'Roboto', sans-serif;
  font-weight: 700; /* Bold weight */
  font-size: 0.9em; /* Adjust size as needed */
  line-height: 1.2; /* Adjust line height for spacing between lines */
}
.font-weight-medium-sm {
  font-family: 'Roboto', sans-serif; /* Use a consistent font family */
  font-weight: 700; /* Bolder medium weight */
  font-size: 1.5em; /* Adjust size as needed */
  letter-spacing: 0.05em; /* Adjust letter spacing if needed */
  line-height: 1.4; /* Adjust line height for better spacing between lines */
}


/* Large Font Size for Small Screens */
.large-font-sm {
  font-size: 2em; /* Adjust size as needed */
}

/* Apply these styles only on small screens and below */
@media (max-width: 767.98px) {
  .font-weight-light-sm {
    font-size: 1.5em; /* Adjust font size for smaller screens */
    line-height: 1.3;
  }
  
  .font-weight-bold-sm {
    font-size: 1.1em; /* Adjust font size for smaller screens */
    line-height: 1.1;
  }

  .large-font-sm {
    font-size: 1.8em; /* Slightly smaller font size for small screens */
  }
}
.text-white-pure {
  color: #ffff
}
.text-white {
  color: #EDEADE;
}
.text-eggshell {
  color: #EDEADE;
}
.text-charcoal {
  color: #36454F;
}
.text-silver {
  color: #C0C0C0;
}
.text-platinum {
  color: #E5E4E2;
}
.ivory {
  color: #FFFFF0;
}
.accursed-black {
  color: #090806;
}
.text-warm {
  color: #f7f5f2;
}
.text-neutral {
  color: #f7f5f2;
}
.neutral-blue {
  color: #0c225d;
}
.small-width-btn {
  width: 160px;
}